import React from "react";
import "./ComingSoon.css";

const ComingSoon = () => {
  return (
    <div className="comingSoon">
      <h2>Coming Soon</h2>
    </div>
  );
};

export default ComingSoon;
